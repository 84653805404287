/*greyscale palette
#fff
#ccc
#555
#222
*/

/*color palette
light blue:  #5b9ff5
darker blue: #3c65d1
pink:        #e884c0
*/


#footer {
    padding: 60px 0;
    background: #222;
    z-index: 1000;
    color: #fff;
}

.links {
    font-size: 40px;
    letter-spacing: 8px;
}

.links a{
    color: #3c65d1;
    text-decoration: none;
}

