/*greyscale palette
#fff
#ccc
#555
#222
*/

/*color palette
light blue:  #5b9ff5
darker blue: #3c65d1
pink:        #e884c0
*/





#services {
    background: #5b9ff5;
    color: #fff;
    margin: 2px;

}

.services {
    justify-content: right;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
}

@media (max-width: 768px) {
    .services {
        display: inline-block;
    }
}

.service {
    padding: 10px;
}

.font_awesome {
    filter: drop-shadow(6px 6px 2px #2275b2);
    font-size: 60px;
}


#services .headline {
    color: #fff;
    border-bottom: solid 1px #fff;
    border-top: solid 1px #fff;

}