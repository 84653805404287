/*greyscale palette
#fff
#ccc
#555
#222
*/

/*color palette
light blue:  #5b9ff5
darker blue: #3c65d1
pink:        #e884c0
*/

* {
    font-family: "Segoe UI", san-serif;
}

.text {
    font-family: "Segoe UI", san-serif;
}


.App {
    text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
    background-color: #222;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #5b9ff5;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*parallax effect start*/
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Georgia, serif;
}


/*
.background {
    width: 2080px;
    height: 1280px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
}
    */

.background {
    background-image: url(./assets/img/banner.jpg);
    height: 100%;
    /* parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}





.headline {
    font-family: "Roboto Slab", sans-serif;
    display: inline-block;
    font-size: xxx-large;
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
}

.container {
    padding: 20px;
    padding-bottom: 30px;
}