/*greyscale palette
#fff
#ccc
#555
#222
*/

/*color palette
light blue:  #5b9ff5
darker blue: #3c65d1
pink:        #e884c0
*/


#projects .headline {
    color: #fff;
    border-bottom: solid 1px #fff;
    border-top: solid 1px #fff;
    filter: drop-shadow(2px 2px 2px #222);
}

/*
#projects {
    background-image: url(../../assets/img/mcecs.jfif);
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
*/

/*
background: rgba(0, 0, 0, 0.0);
        background: #fff;
        border: 40px solid #222;
        border-bottom: 0;
    border-top: 0;
*/

.project_item {
    border: 40px solid #222;
    background: #fff;
    padding: 80px;
    margin: 0 auto;
    width: 100px;
    height: 400px;
    box-sizing: border-box;
    transition: transform .2s;
}

.project_item:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.photo {
    height: 75%;
    width: 75%;
}

.photo:hover {

}












.card a:link, .card a:visited {
    text-decoration: none;
    display: inline-block;
}

.card {
    border: 25px solid #fff;
    transition: transform .2s;
}

.card:hover {
    transform: scale(1.05);

}

.card-body {
    background: #fff;
    width: 100%;
    vertical-align: top;
}

.card-title {
    text-align: center;
    font-family: "Roboto Slab", sans-serif;
}

.card-text {
    font-family: san-serif;
}

.card-content {
    text-align: left;
    color: #222;
    padding: 15px;
}


#projects *, *:before, *:after {
    box-sizing: border-box;
}


#projects .slide-container {
    margin: auto;
    width: 600px;
    text-align: center;
}

#projects .wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
}

    #projects .wrapper:focus {
        outline: 0;
    }

.card {
    background: white;
    width: 300px;
    display: inline-block;
    margin: auto;
    border-radius: 19px;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px black;
    z-index: 9999;
}

.card__image {
    position: relative;
    height: 200px;
    margin-bottom: 35px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.card__image img {
    width: 200px;
    position: absolute;
    top: 25px;
    left: 25px;
}

.card__type {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
}

.card__type {
    color: #3c65d1;
}


.card__name {
    font-size: 26px;
    color: #555;
    font-weight: 900;
    margin-bottom: 5px;
    font-family: "Roboto Slab", sans-serif;
}

.card__description {
    padding: 20px;
    margin-bottom: 10px;
    text-align: left;
}

.card__stats {
    background: #5b9ff5;
}


.card__stats {
    color: white;
    font-weight: 700;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}

    .card__stats .one-third {
        width: 33%;
        float: left;
        padding: 20px 15px;
    }

    .card__stats .stat {
        position: relative;
        font-size: small;
    }

    .card__stats .no-border {
        border-right: none;
    }

#projects .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

#projects .slick-prev {
    left: 100px;
    z-index: 999;
}

#projects .slick-next {
    right: 100px;
    z-index: 999;
}


.WEB {
    font-family: "Calibri Body", calibri;
    white-space: nowrap;
}

.ASSEMBLY {
    font-family: "Calibri Light", calibri;
    font-size: small;
}

.sources {
    background: #e884c0;
    padding: 10px 15px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}




.source {
    background-color: #e884c0;
    border: solid 1px #fff;
    color: #fff;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 3px;
    font-size: medium;
    font-family: "Segoe UI", san-serif;
}

    .source a {
        color: #fff;
    }


    .source:hover a{
        background-color: #fff;
        color: #222;
    }

    .source:hover {
        background-color: #fff;
    }




















@media (max-width: 768px) {
    .center {

    }
}


#projects .slick-current {
    position: inherit;
    z-index: 10000;
}