/*greyscale palette
#fff
#ccc
#555
#222
*/

/*color palette
light blue:  #5b9ff5
darker blue: #3c65d1
pink:        #e884c0
*/

#about {
    background-color: #e884c0;
    width: 100%;
    padding: 100px;
    font-size: 20px;
    color: #fff;
    margin: 2px;
}
/*end parallax effect*/

    #about .headline {
        color: #fff;
        border-bottom: solid 1px #fff;
        border-top: solid 1px #fff;

    }


.resume {
    background-color: #e884c0;
    border: solid 1px #fff;
    color: #fff;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 3px;
    font-size: medium;

}

    .resume:hover {
        background-color: #fff;
        color: #222;
    }