/*greyscale palette
#fff
#ccc
#555
#222
*/

/*color palette
light blue:  #5b9ff5
darker blue: #3c65d1
pink:        #e884c0
*/



@media only screen and (max-width: 480px) {

}



.home {
    background-image: url(../../assets/img/banner.jpg);
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 250px;
    padding-top: 250px;
}


#home .headline {
    color: #fff;
    border-bottom: solid 1px #fff;
    border-top: solid 1px #fff;
}


.home__text {
    margin: 10px 0;
    font-size: 60px;
    font-family: "Roboto Slab", sans-serif;
    color: #fff;
    filter: drop-shadow(2px 2px 2px #222);
}


.home__caption {
    margin: 0;
    font-size: 20px;
    font-family: "Roboto Slab", sans-serif;
    color: #fff;
    filter: drop-shadow(2px 2px 2px #222);
}