/*greyscale palette
#fff
#ccc
#555
#222
*/

/*color palette
light blue:  #5b9ff5
darker blue: #3c65d1
pink:        #e884c0
*/

#testimonials {
    background-image: url(../../assets/img/mcecs.jfif);
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



#testimonials .headline {
    color: #fff;
    border-bottom: solid 1px #fff;
    border-top: solid 1px #fff;
    filter: drop-shadow(2px 2px 2px #222);
}

.testimonials {
    background: #fff;
}

#testimonials h3 {
    font-family: didot;
    font-style: italic;
}





.sources {
    background: #e884c0;
    padding: 10px 15px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.quote {
    color: #222;
    padding-top: 10px;
}

.position {
    font-family: "Roboto Slab", sans-serif;
    color: #222;
}





yellow {
    background-color: #ff9;
    box-shadow: 0 3px 5px -3px #000;
    color: #009;
    float: right;
    font-family: cursive;
    font-size: 16px;
    font-style: italic;
    height: 22em;
    margin: 1em;
    padding: 2em;
    transform: rotate(-4deg);
    width: 22em;
    text-align: left;
}
    yellow a {
        color: #3c65d1;
        text-decoration: none;
        font-family: "Roboto Slab", sans-serif;
        text-align: left;
    }

pink {
    background-color: #efa0c3;
    box-shadow: 0 3px 5px -3px #000;
    color: #009;
    float: right;
    font-family: cursive;
    font-size: 16px;
    font-style: italic;
    height: 19em;
    margin: 1em;
    padding: 2em;
    transform: rotate(6deg);
    width: 19em;
    text-align: left;
}
    pink a {
        color: #3c65d1;
        text-decoration: none;
        font-family: "Roboto Slab", sans-serif;
        text-align: left;
    }

blue {
    background-color: #addef2;
    box-shadow: 0 3px 5px -3px #000;
    color: #222;
    float: right;
    font-family: cursive;
    font-size: 16px;
    font-style: italic;
    height: 23em;
    margin: 1em;
    padding: 2em;
    transform: rotate(2deg);
    width: 23em;
    text-align: left;
}
    blue a {
        color: #3c65d1;
        text-decoration: none;
        font-family: "Roboto Slab", sans-serif;
        text-align: left;
    }


green {
    background-color: #c9fbd6;
    box-shadow: 0 3px 5px -3px #000;
    color: #222;
    float: right;
    font-family: cursive;
    font-size: 16px;
    font-style: italic;
    height: 19em;
    margin: 1em;
    padding: 2em;
    transform: rotate(-2deg);
    width: 19em;
    text-align: left;
}

    green a {
        color: #3c65d1;
        text-decoration: none;
        font-family: "Roboto Slab", sans-serif;
        text-align: left;
    }



orange {
    background-color: #fdc3a9;
    box-shadow: 0 3px 5px -3px #000;
    color: #222;
    float: right;
    font-family: cursive;
    font-size: 16px;
    font-style: italic;
    height: 21.5em;
    margin: 1em;
    padding: 2em;
    transform: rotate(5deg);
    width: 21.5em;
    text-align: left;
}

    orange a {
        color: #3c65d1;
        text-decoration: none;
        font-family: "Roboto Slab", sans-serif;
        text-align: left;
    }





























#testimonials .container {
    margin: 0 auto;
    padding: 40px;
    width: 80%;
    color: #333;
    background: #2d2d2d !important;
}

#testimonials .variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
}

#testimonials .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1.08);
}

#testimonials .center h3 {
    opacity: 0.8;
    transition: all 300ms ease;
}

#testimonials .content {
    padding: 20px;
    margin: auto;
    width: 90%;
}

#testimonials .slick-slide .image {
    padding: 10px;
}

#testimonials .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
}

    #testimonials .slick-slide img.slick-loading {
        border: 0;
    }

#testimonials .slick-slider {
    margin: 30px auto 50px;
}

#testimonials .slick-dots {
    margin-left: 0;
}

#testimonials .slick-thumb {
    bottom: -45px;
}

    #testimonials .slick-thumb li {
        width: 60px;
        height: 45px;
    }

        #testimonials .slick-thumb li img {
            filter: grayscale(100%);
        }

        #testimonials .slick-thumb li.slick-active img {
            filter: grayscale(0%);
        }

@media (max-width: 768px) {
    #testimonials h3 {
        font-size: 24px;
    }

    #testimonials .center {
        margin-left: -40px;
        margin-right: -40px;
    }

        #testimonials .center .slick-center h3 {
            color: #e67e22;
            opacity: 1;
            transform: scale(1);
        }

        #testimonials .center h3 {
            opacity: 0.8;
            transform: scale(0.95);
            transition: all 300ms ease;
        }
}

#testimonials .slick-vertical .slick-slide {
    height: 180px;
}

#testimonials .slick-arrow {
    background-color: grey;
    /* color: black; */
}

    #testimonials .slick-arrow:hover {
        background-color: grey;
    }
/* .slick-prev {
  z-index: 100;
  left: 20px !important;
}
.slick-next {
  z-index: 100;
  right: 20px !important;
} */

#testimonials .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
    height: 286px;
    width: 218px;
    border-radius: 10px;
    margin: 50px auto;
    background: linear-gradient( to right, rgba(0, 0, 0, 0) 56%, rgba(0, 0, 0, 0.68) 100% );
    box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.29);
    outline: none;
    transition: transform 0.5s linear;
    transform-style: preserve-3D;
    transform: scale(0.97);
}

#testimonials .center .slick-center img {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.2);
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease;
    background: linear-gradient( to bottom, rgba(0, 0, 0, 0) 39%, rgba(0, 0, 0, 0.68) ), linear-gradient(to bottom, #00c8ff, #420dff 100%);
}

#testimonials button.slick-arrow.slick-prev {
    left: 27% !important;
}

#testimonials .slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;
}

#testimonials button.slick-arrow {
    z-index: 1;
    border-radius: 45px;
    width: 64px !important;
    height: 64px !important;
    opacity: 0.7;
    background-color: #000000 !important;
}

#testimonials .slick-initialized .slick-slide {
    display: block;
}

#testimonials .slick-current {
    color: white !important;
    transform: scale(1.01, 1.01) !important;
    height: 350px;
    filter: grayscale(0%);
    transition: transform 0.5s linear;
    transform-style: preserve-3D;
    position: inherit;
}

#testimonials .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    z-index: 2;
}

#testimonials .slick-slider .slick-list,
#testimonials .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

#testimonials .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

#testimonials .slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: block;
}

#testimonials button.slick-arrow.slick-prev {
    left: 27% !important;
}

#testimonials button.slick-arrow {
    z-index: 1;
    border-radius: 45px;
    width: 64px !important;
    height: 64px !important;
    opacity: 0.7;
    background-color: #000000 !important;
}

    #testimonials button.slick-arrow.slick-next {
        left: 67% !important;
    }

#testimonials .slick-next:before,
.slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
}