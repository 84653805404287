/*greyscale palette
#fff
#ccc
#555
#222
*/

/*color palette
light blue:  #5b9ff5
darker blue: #3c65d1
pink:        #e884c0
*/

#navbar {
    overflow: hidden;
    background-color: #fff;
    z-index: 999;
    height: 60px;
    font-size: x-large;
}

/* Navbar links */
#navbar a {
    float: left;
    display: block;
    color: #555;
    text-align: center;
    padding: 14px;
    text-decoration: none;
    font-family: "Roboto Slab", sans-serif;
}

#navbar a:hover {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
    font-weight: bold;
}

#navbar a:before {
    transition: transform 0.5s;
    transform-origin: right;
    transform: scaleX(0);
    font-weight: bold;
    font-family: "Roboto Slab", sans-serif;
}

#navbar a.active {
    background-color: #fff;
    color: #3c65d1;
}

/* Page content */
.content {
    padding: 16px;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 60px;
}


/* for anchor points*/
:target {

}

.pic {
    height: 5%;
    width: 5%;
}
